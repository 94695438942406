export const job_purpose_of_employment_array = [
  { key: "fishing_boats", value: "Fishing boats" },
  { key: "seafood_processing_factory", value: "Seafood processing factory" },
  { key: "manufacturing_factory", value: "Manufacturing factory" },
  { key: "restaurants", value: "Restaurants" },
  { key: "thai_message", value: "Thai Message" },
  { key: "construction", value: "Construction" },
  { key: "agriculture_farms", value: "Agriculture farms" },
  { key: "others", value: "Others" },

];